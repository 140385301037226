.subscribe-container {
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 5%;
}

.title-container {
    text-align:center;
    padding-bottom: 20%;
}

.title-container h1 {
    font-family: 'Dancing Script', cursive; 
    font-size: 3em;   
    color: #242424;
    transform: rotate(-10deg);
    
}


.subscribe-flex {
    display: flex;
    justify-content:center;
    gap:15%;
}

.subscription-flex-container {
    display: flex;
   
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.subscribe-button {
    display: inline-block;
    width: 300px;
    height: 40px;
    padding: 5px 25px;
    background-color: #DE6769;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 2px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.subscribe-button:hover {
    transform: scale(1.1);
}

.heart-image {
    max-width: 100%;
    width: 450px;
    max-height: 100%;
    margin-top: 0;
}

.session-container {
    display: flex;
    margin-top: 10%;
    margin-left:2%;
}

.session-text {
    padding-right: 10%;
}

.talk-button {
    margin-right: 25%;
    
}

.talk-button button{
    display: inline-block;
    width: 150px;
    height: 35px;
    padding: 5px 25px;
    background-color: #DE6769;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 2px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.talk-button button:hover{
    transform: scale(1.1);

}

@media screen and (min-width:2800px) and (max-width: 3440px) {
    .subscribe-container {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: -10%;
    }
    
    .session-container {
        display: flex;
        justify-content: center;
        margin-left:15%;
    }
    
}

@media screen and (min-width:2000px) and (max-width: 2799px) {
    .subscribe-container {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: -5%;
    }

    .session-container {
        display: flex;
        justify-content: center;
        margin-left:15%;
    }
    
}

@media screen and (max-width: 768px) {
    .subscribe-flex {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .subscribe-button {
        width: 200px;
    }

    .title-container {
        margin-left:0;
        text-align: center;
    }

    .title-container h1 {
        padding-bottom: 10%;
    }

    .session-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .talk-button {
        margin-right: 0;
        
    }

    .session-text {
        padding-right: 0;
    }
    
    .heart-image {
        margin-top: 5%;
    }
   
 }
.carousel {
    padding: 5rem;
}

.carousel-heading {
    font-family: 'Merriweather', serif;
    font-size: 2em;
    color:#797373;
    text-align: center;
}

.carousel-heading-cursive{
    font-family: 'Dancing Script', cursive;
    font-size: 1.5em;  
    color: #242424;
    font-weight: lighter;
    padding-left: 10px;
}

.box{
    max-width: 100%;
   
}


.box h3 {
    text-align: center;
}

.slick-slide div {
    margin-left:5px;
}

.slick-prev {
    color:#DE6769 !important;

}

.slick-next {
    color:#DE6769 !important;
}

.slick-prev:before, .slick-next:before {
    color:#DE6769 !important;
  
}

.slick-dots li.slick-active button:before {
    color: #DE6769 !important;
}

.slick-dots li button:before {
    color: #DE6769 !important;
    font-size: .8rem !important;
}

@media screen and (min-width:2800px) and (max-width: 3440px) {
    .carousel {
        padding: 45rem;
        margin-top: -400px;
    }

    .slick-slide div {
        margin-left:35px;
    }
    
}


@media screen and (min-width:2000px) and (max-width: 2799px) {
    .carousel {
        padding: 20rem;
        margin-top: -100px;
    }

    .slick-slide div {
        margin-left:35px;
    }
    
}




@media screen and  (max-width: 769px) {

    .box{
       align-items: center;
       text-align: center;
    }
    
    .carousel {
        padding: 3rem;
    }

    .slick-slide div {
        margin-left:0;
    }
    
    .responsive-iframe {
        margin-left: 0;
    }
    

    
    
 }
.banner-container{
    margin-top: 3%;
    background-color: #A58A93;
    height: fit-content;
}

.banner-content {
    display: flex;
    justify-content: space-around;
}

.banner-content-left h3 {
    position: relative;
    padding-top: 2%;
    color: #F4D8D7;
    font-family: 'Merriweather', serif;
    font-weight: 300;
}
.click-here-container {
    display: flex;
    justify-content: center;
}

.click-here {
    display: inline-block;
    margin-top: 20px;
    padding: 8px 30px;
    background-color: #F4D8D7;
    text-transform: uppercase;
    margin: 0;
    color: #6e535c;
    text-decoration: none;
    border-radius: 2px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    transition: transform 0.3s ease;
}

.click-here:hover {
    transform: scale(1.1);
}

.banner-content-right {
    display: flex;
}

.banner-image-container{
    background-color: #E1CAC2;
    width: 250px;
    height: auto;
    text-align: center;
    
}   


.banner-image-container img {
    max-width: 100%;
    width: 180px;
    height: 110px;
}

.exclamation {
    position: absolute;
    top:-3%;
    left: 95%;
    width: 50px;
}

@media screen and (max-width: 600px) {
    .banner-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 20px;
    }
    
    .banner-content-right {
        display: flex;
        justify-content: center;
    }
    
    .exclamation {
        position: absolute;
        top:-3%;
        left: 72%;
        width: 50px;
    }
 }

 @media screen and (max-width: 480px) {
    
    .exclamation {
        position: absolute;
        top:-3%;
        left: 75%;
        width: 50px;
    }
 }

 @media screen and (max-width: 400px) {
    
    .exclamation {
        position: absolute;
        top:-3%;
        left: 78%;
        width: 50px;
    }
 }

 @media screen and (max-width: 360px) {
    
    .exclamation {
        position: absolute;
        top:-3%;
        left: 80%;
        width: 50px;
    }
 }

 @media screen and (max-width: 340px) {
    
    .exclamation {
        position: absolute;
        top:-3%;
        left: 82%;
        width: 50px;
    }
 }
.footer{
    margin-top: 9%;
}

.footer-flex-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 8%;
    gap: 10%;
}

.footer-flex-container h1{
    text-transform: uppercase;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 1.7em;
}


.footer-menu-flex {
    display: flex;
    gap:40px;
    margin-top: 2%;
}

.footer-menu-flex li {
    list-style: none;
}

.footer-items {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: .9em;
    text-transform: uppercase;
    color: #000;
    text-decoration: none;
}

.socials-container {
    text-align: center;
    padding-top: 1px;
}

.socials-container span {
    padding-left: 10px;
}

.socials-container a {
    color:black;
}

.socials-container a:hover {
    color:#DE6769;
}

@media screen and (min-width: 2560px) and (max-width: 3440px) {
    .footer-flex-container {
        justify-content: flex-start;
        margin-left: 25%;
    }
  
}

@media screen and (max-width: 768px) {
    .footer-flex-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }

    .footer-menu-flex {
        display: flex;
        gap:20px;
        margin-top: 0;
    }
    
    .socials-container {
        margin-top: 0;
        text-align: center;
    }

}
  

@media screen and (max-width: 400px) {
    .footer-items {
        font-size: .7em;
    }
}
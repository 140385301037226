.about-container {
    padding-top: 60px;
}
.about-content {
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

.about-heading {
    text-align: center;
    color: #C4767A;
    font-family: 'Merriweather', serif;
    text-transform: uppercase;
}

.about-heading-cursive {
    font-family: 'Dancing Script', cursive;
    color: #242424;
    font-weight: lighter;
    font-size: 1.5em;
    padding-left: 10px;
}

.about-image {
    max-width: 100%;
    max-height: 100%;
  
}

.about-image img.scroll-hidden {
    opacity: 0;
    transition: opacity 0.5s;
}

.about-image img.scroll-visible {
    opacity: 1;
}

.about-image img {
    width:300px;
    height:450px;
}



.about-paragraph-container {
    font-family: 'Noto Sans Sinhala', sans-serif;
    font-size: 1.1em;
    text-align:left;
    margin-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: #FBF6F0;

}


@media screen and (max-width: 768px) {
    .about-content {
       flex-direction: column;
       margin-left: 0;
       margin-right: 0;
       align-items: center;
      
    }

    .about-heading {
       margin-top: 20px;
    }
    
    .about-paragraph-container {
        padding-left: 10%;
        padding-right: 10%; 
        align-items: center;   
    }
    
 }
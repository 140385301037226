.channel-container {
    display: flex;
    justify-content: center;
    height: fit-content;
    margin-left: 8%;
    margin-top: 10%;
    margin-right: 8%;
}

.channel p{
    font-family: 'Noto Sans Sinhala', sans-serif;
    font-size: 1em;
    padding-right: 15%;
}

.channel h1 {
    font-family: 'Merriweather', serif;
    font-size: 3em;
    font-weight: 600;
    text-align: left;
    color: #797373;
    font-size: 4.5em;
    padding-top: 10%;
    padding-bottom: 20%;
}


.channel button {
    display: inline-block;
    margin-top: 30px;
    padding: 7px 25px;
    background-color: #DE6769;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 2px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.channel button:hover{
    transform: scale(1.1);
}

.popular-videos-container {
    background-color: #CFBEC4;
    width: fit-content;
    height: fit-content;
    padding-right:5%;
    padding-left: 5%;
    flex-grow: 1;
    
}

.channel-image-container {
    max-width: 100%;

}

.channel-image-container img{
    width: 160px;

}


.channel-video-container {
    background-color: #CFBEC4;
    height: fit-content;
    width: 900px;
    padding-left: 2%;
    padding-right: 2%;
    
}

.channel-video-container h2 {
    font-family: 'Merriweather', serif;
    font-weight: 400;
    font-size: 1.5em;
    padding-top: 10%;
    padding-bottom: 5%;
}




.channel-video-content{
    display: flex;
    gap: 3%;
    padding-bottom: 8%;
}



.channel-video-image {
    max-width: 100%;
    width: 180px;
    height: 120px;
    max-height: 100%;
}

.channel-video-text {
    font-family: 'Noto Sans Sinhala', sans-serif;
    font-size: .95em;
    text-transform: uppercase;
}

.play-button-container {
    display: flex;

}

.play-button {
    color: #DE6769;
    cursor: pointer;
    transition: transform 0.3s ease;

}

.play-button:hover {
    transform: scale(1.5);
}



@media screen and (max-width: 1030px) {
    .channel-video-content{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .channel-video-image {
        max-width: 100%;
        width: 250px;
        height: 150px;
        max-height: 100%;
    }
    
   
}

@media screen and (max-width: 768px) {

    .channel-container {
        flex-direction: column;
        align-items: center;

    }

    .channel-video-container {
        background-color: #CFBEC4;
        height: fit-content;
        width: 500px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        margin-top: 10%;
        
    }

    .channel-video-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        gap: 0;
        padding-bottom: 5%;
    }
    
    .channel-video-text {
        padding-left: 8%;
        padding-right: 8%;
    }

    .channel {
        margin-left: 5%;
        margin-right: 5%;
        padding-bottom:5%;
    }

    .play-button-container {
        justify-content: center;
    }
   
 }

 @media screen and (max-width: 500px) {
    .channel-video-container {
        background-color: #CFBEC4;
        height: fit-content;
        width: 300px;
        padding-left: 0;
        padding-right: 0;
        
    }
 }
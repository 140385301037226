.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  padding-top: 2%;
  padding-bottom: 2%;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1%;
  padding-left: 2%;
}

.logo-title {
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 2em;
  color: black !important;
  text-decoration: none;
}

.logo h4 {
  font-family: 'Dancing Script', cursive;
  font-size: 1.2em;
  margin-top: -15px;
  
}

.hamburger-icon {
  display: none; 
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 20px;
}

.hamburger-icon div {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 5px auto;
  transition: transform 0.3s, opacity 0.3s;
}


.menu-items-container{
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.menu-items {
  display: flex;
  justify-content:center;

}

.lets-talk-container {
  margin-left: auto;
  margin-right: 5%;
}

.lets-talk-button {
  display: inline-block;
  padding: 4px 20px;
  background-color: #DE6769;
  color: #fff;
  text-decoration: none;
  border-radius: 2px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  transition: transform 0.3s ease;
}

.lets-talk-button:hover{
  transform: scale(1.1);
}


.menu-items ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.menu-items li {
  margin-right: 20px;
  
}

.nav-items {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: .9em;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
}

.nav-items:hover {
  text-decoration: underline;
}

.menu-items.show {
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 2560px) and (max-width: 3440px) {
  .logo {
    padding-left: 18%;
  }
  
  .menu-items-container{
    flex-grow: 1;
    display: flex;
    justify-content:flex-start;
    margin-left: 20%;
  }

  .lets-talk-container {
    margin-left: auto;
    margin-right: 20%;
  }

}



@media screen and (max-width: 842px) {

  
  .logo-title {
    font-size: 1.7em;
  }
  
  .hamburger-icon {
    display: block;
    margin-top: 25px;
  }

  .menu-items {
    display: none;
  }

  .menu-items.show {
    display: flex;
  }

  .menu-items ul {
    flex-direction: column;
    margin: auto;
    gap: 15px;
  }

  
  .lets-talk-container {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 30px;
    padding-right: 80px;
  }
}


@media screen and (max-width: 500px) {
  .hamburger-icon {
    display: block;
    margin-top: 12px;
    right: 0px;
  }

  .logo-title {
    font-size: 1.4em;
  }

  .logo h4 {
    font-size: .9em;
    margin-top: -10px;
  }

  .lets-talk-button {
    display: inline-block;
    padding: 2px 8px;
    background-color: #DE6769;
    color: #fff;
    text-decoration: none;
    border-radius: 2px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 0.3s ease;
  }
   
  .lets-talk-container {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 20px;
    padding-right: 60px;
  }
  
  .menu-items.show {
    top: 65px;
  }
  
}


@media screen and (max-width: 370px) {
  .hamburger-icon {
    display: block;
    margin-top: 10px;
    right: 0px;
  }

  .logo-title {
    font-size: 1.1em;
  }

  .logo h4 {
    font-size: .9em;
    margin-top: -8px;
  }

  .lets-talk-button {
    display: inline-block;
    padding: 2px 3px;
    background-color: #DE6769;
    color: #fff;
    text-decoration: none;
    border-radius: 2px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 0.3s ease;
  }
   
  .lets-talk-container {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 18px;
    padding-right: 60px;
  }
  
  .menu-items.show {
    top: 55px;
  }
  
}
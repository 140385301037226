.hero-container {
    background-color: #F4DAD9;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .hero-content-container {
    display: flex;
    justify-content: space-around;
    margin-left: 2%;
    margin-right: 2%;
  }

  .hero-text-container {
    position: relative;
    padding-top: 7%;
  }

  .hero-text-container h2 {
    font-family: 'Merriweather', serif;
    color: #B87773;
    font-weight: 500;
    font-size: 2.3em;
    text-align: center;
  }

  .hero-text-container p {
    font-family: 'Noto Sans Sinhala', sans-serif;
    font-size: 1.1em;
    text-align: center;
    margin-top: 5%;
    padding-left: 8%;
    padding-right: 8%;
  }

  .hero-text-container h4 {
    font-family: 'Dancing Script', cursive;    
    color: #242424;
    font-weight: lighter;
    font-size: 2.3em;
    text-align: center;
  }
  
  
  .hero-image-container {
    max-width: 100%;
  }

  .feminine-image {
    width: 600px;
  }

  .book-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }

  .book-button-container button {
      padding: 8px 30px;
      background-color: #DE6769;
      color: #fff;
      text-decoration: none;
      border-radius: 2px;
      border: none;
      font-size: 16px;
      cursor: pointer;
      text-transform: uppercase;
      transition: transform 0.3s ease;
  }

  .book-button-container button:hover {
    transform: scale(1.1);
  }

  .arrow-image {
    position: absolute;
    top: 50%;
    left: 60%;
    width: 100%;
    max-width: 160px;
    transform: rotate(20deg);
 
  }

  @media screen and (max-width: 3440px) {
    .hero-text-container {
      padding-top: 2%;
    }

    .hero-content-container {
      display: flex;
      justify-content: center;
      margin-left: 2%;
      margin-right: 2%;
    }
  
  
  }
 
  @media screen and (max-width: 1081px) {

    .arrow-image {
      top: 60%;
      left: 65%;
      width: 100%;
      max-width: 120px;
      transform: rotate(15deg);
 
    }
  }

  @media screen and (max-width: 1010px) {

    .hero-text-container {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 980px) {

    .hero-text-container {
      padding-top: 0;
    }
  
    .hero-content-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .book-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 5%;
      
    }

    .arrow-image {
      top: 55%;
      left: 65%;
      width: 100%;
      max-width: 130px;
      transform: rotate(20deg);
 
    }
  }



  @media screen and (max-width: 600px) {

    .feminine-image {
      width: 300px;
   
    }

    .hero-text-container h2 {
      font-size: 1.7em;
      
    }

    .hero-text-container p {
      font-size: 1em;
    
     
    }
  
    .hero-text-container h4 {
      font-size: 1.5em;
  
    }

    .arrow-image {
      top: 60%;
      left: 65%;
      width: 100%;
      max-width: 100px;
      transform: rotate(15deg);
 
    }
 
  
  }
  

  @media screen and (max-width: 450px) {
    .arrow-image {
      top: 60%;
      left: 70%;
      width: 100%;
      max-width: 90px;
      transform: rotate(10deg);
 
    }
 
  }

  @media screen and (max-width: 340px) {
    .arrow-image {
      top: 60%;
      left: 75%;
      width: 100%;
      max-width: 90px;
      transform: rotate(0deg);
 
    }
 
  }
  
  